import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import ContactsModal from '@/components/modals/ContactsModal/ContactsModal';
import useSettingItem from '@/hooks/useSettingItem';

import Button, { ButtonLink } from '../Button';

type Props = {
  isCareers?: boolean;
  isTitles?: boolean;
  formWorker?: string;
  formWorkerName?: string;
  formAdmin?: string;
  formAdminName?: string;
};

function CareersNotFound({
  isCareers,
  isTitles = true,
  formAdmin = '',
  formAdminName = '',
  formWorker = '',
  formWorkerName = '',
}: Props) {
  const openModal = useModal();

  const [formWorkerUrl, setFormWorkerUrl] = useState<string>(formWorker);
  const [formAdminUrl, setFormAdminUrl] = useState<string>(formAdmin);

  const careersNotFoundTitle = useSettingItem('careersNotFoundTitle');
  const careersNotFoundText = useSettingItem('careersNotFoundText');
  const careersNotFoundButtonLabel = useSettingItem(
    'careersNotFoundButtonLabel'
  );
  const careersNotFoundFormWorker = useSettingItem(
    'CAREERS_NOT_FOUND_FORM_WORKER'
  );
  const careersNotFoundFormWorkerName = useSettingItem(
    'CAREERS_NOT_FOUND_FORM_WORKER_NAME'
  );
  const careersNotFoundFormAdmin = useSettingItem(
    'CAREERS_NOT_FOUND_FORM_ADMIN'
  );
  const careersNotFoundFormAdminName = useSettingItem(
    'CAREERS_NOT_FOUND_FORM_ADMIN_NAME'
  );

  useEffect(() => {
    if (!formWorker && careersNotFoundFormWorker?.url) {
      setFormWorkerUrl(careersNotFoundFormWorker.url);
    }
    if (!formAdmin && careersNotFoundFormAdmin?.url) {
      setFormAdminUrl(careersNotFoundFormAdmin.url);
    }
  }, []);

  function openContactsModal() {
    openModal(ContactsModal, {
      // formAdmin: formAdminUrl,
      // formAdminName: formAdminName,
      // formWorker: formWorkerUrl,
      // formWorkerName: formWorkerName,
      formAdmin:
        careersNotFoundFormAdmin?.url === null
          ? undefined
          : careersNotFoundFormAdmin?.url,
      formAdminName: careersNotFoundFormAdminName || undefined,
      formWorker:
        careersNotFoundFormWorker?.url === null
          ? undefined
          : careersNotFoundFormWorker?.url,
      formWorkerName: careersNotFoundFormWorkerName || undefined,
    });
  }

  return (
    <Component>
      <Inner>
        {isTitles && (
          <Top>
            <Left>
              <Title>{careersNotFoundTitle}</Title>
            </Left>
            <Right>
              <Text>{careersNotFoundText}</Text>
            </Right>
          </Top>
        )}
        <Bottom>
          <Cell>
            <CareersNotFoundButton
              variant="yellow"
              onClick={() => openContactsModal()}
            >
              {careersNotFoundButtonLabel}
            </CareersNotFoundButton>
          </Cell>
          {isCareers ? (
            <Cell>
              <VacanciesButton variant="gray" href={'/careers/vacancies'}>
                ВСЕ ВАКАНСИИ
              </VacanciesButton>
            </Cell>
          ) : null}
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 130px auto 222px;
  padding: 0 40px;

  ${media.laptop(css`
    margin: 130px auto 178px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
    margin: 70px 0 85px 0;
  `)}
  
  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Inner = styled.div``;

const Top = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Bottom = styled.div`
  margin: 100px -12px 0;
  display: flex;
  justify-content: space-between;

  ${media.laptop(css`
    margin-top: 80px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
  
  ${media.mobile(css`
    flex-direction: column;
    margin-top: 30px;
  `)}
`;

const Left = styled.div`
  flex: 1 1 50%;
  max-width: 50%;

  ${media.mobile(css`
    flex: auto;
    max-width: 100%;
  `)}
`;
const Right = styled.div`
  flex: 1 1 49%;
  max-width: 49%;

  ${media.laptop(css`
    margin-top: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 0;
  `)}
  
  ${media.mobile(css`
    flex: auto;
    max-width: 100%;
    margin-top: 20px;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  max-width: 780px;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.black};

  ${media.laptop(css`
    max-width: 570px;
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    max-width: none;
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const CareersNotFoundButton = styled(Button)`
  display: block;
  width: 100%;
  padding: 31px 0;
  transition: all 0.3s ease-in-out;

  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-transform: uppercase;
  color: ${colors.black};

  ${media.laptop(css`
    padding: 24px 0;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    padding: 22px 0;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    padding: 15px 0;
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Cell = styled.div`
  padding: 0 12px;
  flex: 1 1 auto;

  ${media.tablet(css`
    &:not(:first-child) {
      flex: 1 1 50%;
      max-width: 50%;
    }
  `)}

  ${media.mobile(css`
    &:not(:first-child) {
      margin-top: 20px;
      flex: auto;
      max-width: none;
    }
  `)}
`;

const VacanciesButton = styled(ButtonLink)`
  display: block;
  width: 100%;
  padding: 31px 0;
  transition: all 0.3s ease-in-out;

  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-transform: uppercase;

  ${media.laptop(css`
    padding: 24px 0;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    padding: 22px 0;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    padding: 15px 0;
    font-size: 16px;
    line-height: 20px;
  `)}
`;

export default CareersNotFound;
