import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as RemoveIcon } from '@/assets/svg/remove-file.svg';
import { ReactComponent as FileIcon } from '@/assets/svg/file-icon.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  fileItem: File;
  removeFile: (count: number) => void;
  index: number;
}

function FileItem({ fileItem, removeFile, index }: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          <FileIcon />
          <Title>{fileItem.name}</Title>
        </Left>
        <Right>
          <Remove onClick={() => removeFile(index)}>
            <RemoveIcon />
          </Remove>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.black};
  margin: 0 0 16px 20px;

  &:last-child {
    margin-right: 0;
  }

  ${media.tablet(css`
    margin: 0 0 16px 10px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  padding: 10px;

  ${media.tablet(css`
    padding: 6px;
  `)}
`;

const Left = styled.div`
  display: flex;

  svg {
    width: 26px;
    height: 26px;

    ${media.tablet(css`
      width: 20px;
      height: 20px;
    `)}

    ${media.mobile(css`
      width: 16px;
      height: 16px;
    `)}
  }

  ${media.mobile(css`
    align-items: center;
    justify-content: center;
  `)}
`;

const Right = styled.div`
  margin-left: 10px;
`;

const Title = styled.span`
  display: block;
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 22px;
  `)}

  ${media.mobile(css`
    max-width: 180px;
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const Remove = styled.div`
  width: 26px;
  height: 26px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet(css`
    width: 20px;
    height: 20px;
  `)}

  svg {
    width: 16px;
    height: 16px;
    opacity: 0.5;
    transition: all 0.3s linear;

    ${media.tablet(css`
      width: 12px;
      height: 12px;
    `)}
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

export default FileItem;
