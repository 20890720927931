import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FieldConfig, useField } from 'formik';

import { colors } from '@/constants/theme';
import Spinner from '@/components/Spinner';
import { ReactComponent as PlusIcon } from '@/assets/svg/plus.svg';
import { FilesType } from '@/typings/model';
import TextInput from '@/components/TextInput/TextInput';
import { media } from '@/utils/mixin';
import { uploadCvFile } from '@/services/requests';

import FileItem from './components/FileItem';

type FormikFieldProps = Pick<FieldConfig, 'name' | 'type'>;
type InputProps = Omit<React.ComponentProps<typeof TextInput>, 'name' | 'type'>;

type Props = FormikFieldProps &
  InputProps & {
    isSmall?: boolean;
    fileId: FilesType;
    setFileId: (value: FilesType) => void;
    error?: string;
    isFeedback?: boolean;
    isSentSuccess?: boolean;
    setFile: (value: boolean) => void;
    description?: string;
  };

function UploadFileFinal({
  isSmall,
  isFeedback,
  isSentSuccess,
  fileId,
  setFileId,
  setFile,
  error: customError,
  name = 'fileId',
  type = 'file',
  description,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [isLoadedFile, setIsLoadedFile] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const [validFiles, setValidFiles] = useState<Array<File>>([]);
  const [isChange, setChange] = useState<boolean>(false);

  const [field, meta, helpers] = useField<string>({ name, type });
  const error = customError ?? (meta.touched ? meta.error : '');

  function dragOver(event: React.DragEvent<HTMLInputElement>) {
    event.preventDefault();
  }

  function dragEnter(event: React.DragEvent<HTMLInputElement>) {
    event.preventDefault();
  }

  function dragLeave(event: React.DragEvent<HTMLInputElement>) {
    event.preventDefault();
  }

  function fileDrop(event: React.DragEvent<HTMLInputElement>) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  }

  function handleInputFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const fileList = event.target.files;

    if (!fileList) return;
    handleFiles(fileList);
  }

  function removeFile(count: number) {
    let newFilesId: FilesType = fileId;

    validFiles.splice(count, 1);
    setValidFiles([...validFiles]);

    selectedFiles.splice(count, 1);
    setSelectedFiles([...selectedFiles]);

    newFilesId.splice(count, 1);
    setFileId(newFilesId);
    setChange(true);
    setTimeout(() => {
      setChange(false);
    }, 100);
  }

  function handleFiles(files: FileList) {
    let newFilesId: FilesType = fileId;
    for (let i = 0; i < files.length; i++) {
      setIsLoadingFile(true);
      if (files[i]) {
        setSelectedFiles((prevArray: Array<File>) => [...prevArray, files[i]]);

        uploadCvFile(files[i]).then((res) => {
          newFilesId.push({ id: res.id });
        });
      }
    }
    setFileId(newFilesId);
    setChange(true);

    setTimeout(() => {
      setChange(false);
    }, 100);
    setTimeout(() => {
      setIsLoadingFile(false);
      setIsLoadedFile(true);
    }, 500);
  }

  useEffect(() => {
    setValidFiles(selectedFiles);
  }, [selectedFiles]);

  useEffect(() => {
    if (!isSentSuccess) return;

    setSelectedFiles([]);
    setFileId([]);
    setIsLoadedFile(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [isSentSuccess]);

  useEffect(() => {
    if (isChange) {
      if (selectedFiles.length > 0) {
        setFile(true);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        setFile(false);
      }
    }
  }, [isChange]);

  return (
    <Component
      isSmall={isSmall}
      isFeedback={isFeedback}
      isLoadingFile={isLoadingFile}
      files={selectedFiles}
    >
      <DropContainer
        files={selectedFiles}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        <InputFile
          id="attach-file"
          type={'file'}
          name={'file'}
          ref={fileInputRef}
          onChange={handleInputFileChange}
          multiple={true}
        />

        <InputLabel htmlFor="attach-file" />

        <Content>
          {isLoadingFile ? (
            <Spinner color={'black'} />
          ) : validFiles?.length !== 0 ? (
            <FileList>
              {validFiles?.map((fileItem, index) => {
                return (
                  <FileItem
                    removeFile={(count: number) => removeFile(count)}
                    key={index}
                    fileItem={fileItem}
                    index={index}
                  />
                );
              })}
              <AddBtn htmlFor="attach-file">
                <PlusIcon />
              </AddBtn>
            </FileList>
          ) : (
            <DropMessage
              dangerouslySetInnerHTML={{ __html: description ?? '' }}
            />
          )}
        </Content>
      </DropContainer>
    </Component>
  );
}

const Inner = styled.div<{ files: Array<File> | undefined }>``;

const DropContainer = styled.div<{ files: Array<File> | undefined }>`
  padding: 16px 0;
  min-height: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.files?.length !== 0
      ? css`
          justify-content: flex-start;
          align-items: flex-start;
        `
      : ''}

  ${media.laptop(css`
    min-height: 160px;
  `)}
  
   ${media.tablet(css`
    padding: 10px 0;
  `)}
`;

const DropMessage = styled.p`
  text-align: center;
  max-width: 640px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  span {
    color: ${colors.orange};
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 0.3s linear;
  }

  ${media.laptop(css`
    max-width: 480px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 16px !important;
    line-height: 22px !important;
  `)}
  
  ${media.mobile(css`
    font-size: 12px !important;
    line-height: 20px !important;
  `)}
`;

const Content = styled.div``;

const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 5;
  position: relative;
`;

const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const InputLabel = styled.label`
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
`;

const AddBtn = styled.label`
  position: relative;
  z-index: 5;
  width: 46px;
  height: 46px;
  margin-left: 25px;
  background: rgba(2, 2, 2, 0.05);
  cursor: pointer;
  transition: all 0.3s linear;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet(css`
    width: 33.6px;
    height: 33.6px;
    margin-left: 10px;
  `)}

  ${media.tablet(css`
    width: 32px;
    height: 32px;
  `)}

  &:hover {
    background: rgba(2, 2, 2, 0.1);
  }

  svg {
    width: 36px;
    height: 36px;

    ${media.tablet(css`
      width: 25px;
      height: 25px;
    `)}
  }
`;

const Component = styled.div<{
  isSmall?: boolean;
  isFeedback?: boolean;
  isLoadingFile?: boolean;
  files: Array<File> | undefined;
}>`
  position: relative;
  border: 2px dotted ${colors.black};
  transition: all 0.3s linear;
  ${(props) =>
    props.files?.length === 0
      ? css`
          &:hover,
          &:focus {
            border-color: transparent;
            background: rgba(0, 0, 0, 0.1);

            ${DropMessage} {
              span {
                text-decoration-color: ${colors.orange};
              }
            }
          }
        `
      : css`
          background: ${colors.gray50};
        `};

  ${(props) =>
    props.isSmall
      ? css`
          ${Inner} {
            padding: 16px 0;
            min-height: 116px;
          }

          ${DropMessage} {
            max-width: 400px;
            font-size: 20px;
            line-height: 26px;

            ${media.laptop(css`
              max-width: 320px;
              font-size: 16px;
              line-height: 24px;
            `)}
          }
        `
      : ''}

  ${(props) =>
    props.isFeedback
      ? css`
          ${Inner} {
            min-height: 168px;

            ${media.laptop(css`
              min-height: 120px;
            `)}
          }
        `
      : ''}
  
  ${(props) =>
    props.isLoadingFile
      ? css`
          pointer-events: none;
        `
      : ''}
`;

export default UploadFileFinal;
