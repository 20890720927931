import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import { ContactsFormPayload, sendContactsForm } from '@/services/requests';
import { FilesType } from '@/typings/model';

import ContactsForm, { ContactsFormValues } from './ContactsForm';

type Props = {
  closeModal: () => void;
  vacancyId?: number | null;
  formWorker?: string;
  formWorkerName?: string;
  formAdmin?: string;
  formAdminName?: string;
};

function ContactsFormContainer({
  closeModal,
  vacancyId,
  formAdmin,
  formAdminName,
  formWorker,
  formWorkerName,
}: Props) {
  const [isSentSuccess, setSentSuccess] = useState(false);
  const [fileId, setFileId] = useState<FilesType>([]);

  function handleSubmit(
    values: ContactsFormValues,
    formikHelpers: FormikHelpers<ContactsFormValues>
  ) {
    const payload: ContactsFormPayload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      files: fileId ?? null,
      vacancyId: vacancyId ?? null,
    };

    sendContactsForm(payload)
      .then(() => {
        formikHelpers.resetForm();
        setSentSuccess(true);
      })
      .catch((error) => {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ContactsFormValues>;
        formikHelpers.setErrors(errorMap);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }

  return (
    <Formik<ContactsFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        files: null,
        vacancyId: null,
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formProps) => (
        <ContactsForm
          {...formProps}
          isSentSuccess={isSentSuccess}
          fileId={fileId}
          setFileId={(value: FilesType) => setFileId(value)}
          formWorker={formWorker}
          formWorkerName={formWorkerName}
          formAdmin={formAdmin}
          formAdminName={formAdminName}
        />
      )}
    </Formik>
  );
}

export default ContactsFormContainer;
