import React from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';

import ContactsFormContainer from './components/ContactsForm.container';

type Props = ModalProps<{
  formWorker?: string;
  formWorkerName?: string;
  formAdmin?: string;
  formAdminName?: string;
}>;

function ContactsModal({ closeModal, innerProps }: Props) {
  return (
    <Wrapper>
      <Inner>
        <CloseButton onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
        <ContactsFormContainer
          formWorker={innerProps.formWorker}
          formWorkerName={innerProps.formWorkerName}
          formAdmin={innerProps.formAdmin}
          formAdminName={innerProps.formAdminName}
          closeModal={() => closeModal()}
        />
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 894px;
  width: 100%;
  background: ${colors.white};

  ${media.laptop(css`
    max-width: 672px;
  `)}

  ${media.tablet(css`
    max-width: 596px;
  `)}
  
  ${media.mobile(css`
    overflow-y: auto;
    max-width: 320px;
  `)}
`;

const Inner = styled.div`
  width: 894px;
  padding: 50px 55px;
  position: relative;

  ${media.laptop(css`
    width: 672px;
    padding: 40px 56px;
  `)}

  ${media.tablet(css`
    width: 596px;
    padding: 40px 30px;
  `)}
  
  ${media.mobile(css`
    width: 320px;
    padding: 30px 20px;
  `)}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 18px;
  right: 18px;
  border-radius: 50%;
  transition: background-color 0.1s;
  padding: 20px;
  z-index: 10;

  ${media.laptop(css`
    padding: 10px;
  `)}

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg {
    width: 24px;
    height: 24px;
    display: block;
    fill: currentColor;

    ${media.laptop(css`
      width: 17px;
      height: 17px;
    `)}
  }
`;

export default ContactsModal;
