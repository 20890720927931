import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, FormikProps } from 'formik';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import { TextInputFormik } from '@/components/TextInput';
import Spinner from '@/components/Spinner';
import { ContactsFormPayload } from '@/services/requests';
import { MaskedTextInputFormik } from '@/components/MaskedTextInput';
import { FilesType } from '@/typings/model';
import UploadFileFinal from '@/components/UploadFileFinal';
import useSettingItem from '@/hooks/useSettingItem';
import Checkbox from '@/components/Checkbox';
import ContactFormLinkCard from '@/components/modals/ContactsModal/components/ContactFormLinkCard';

import MessageSuccess from './MessageSuccess';

export type ContactsFormValues = ContactsFormPayload;

type Props = FormikProps<ContactsFormValues> & {
  isSentSuccess: boolean;
  fileId: FilesType;
  setFileId: (value: FilesType) => void;
  formWorker?: string;
  formWorkerName?: string;
  formAdmin?: string;
  formAdminName?: string;
};

function ContactsForm({
  isSentSuccess,
  isSubmitting,
  fileId,
  setFileId,
  formAdmin,
  formAdminName,
  formWorker,
  formWorkerName,
}: Props) {
  const formTitle = useSettingItem('CAREERS_VACANCY_FORM_TITLE');
  const formCheckboxes = useSettingItem('CAREERS_VACANCY_FORM_CHECKBOXES');

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [isFile, setFile] = useState<boolean>(false);

  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const [checkboxValues, setCheckboxValues] = useState<Array<boolean>>(
    formCheckboxes.map(() => false)
  );

  useEffect(() => {
    const isRequiredCheckboxesChecked =
      checkboxValues.filter(
        (checkbox, index) => formCheckboxes[index].required && checkbox
      ).length ===
      formCheckboxes.filter((checkbox) => checkbox.required).length;

    if (
      firstName &&
      lastName &&
      email &&
      phone &&
      isFile &&
      isRequiredCheckboxesChecked
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [
    firstName,
    lastName,
    email,
    phone,
    isFile,
    checkboxValues,
    formCheckboxes,
  ]);

  return (
    <Form autoComplete="off" noValidate>
      <FormContent>
        <MessageSuccess isSentSuccess={isSentSuccess} />
        <FormInner isSentSuccess={isSentSuccess}>
          <Top>
            <Title>{formTitle}</Title>
          </Top>

          <Center>
            <Row>
              <Col>
                {(formWorker || formAdmin) && (
                  <ContactFormLinkList>
                    {formWorker && (
                      <ContactFormLinkCard
                        title={
                          formWorkerName || 'Анкета на рабочие специальности'
                        }
                        type="Скачать  .doc"
                        link={formWorker}
                      />
                    )}
                    {formAdmin && (
                      <ContactFormLinkCard
                        title={
                          formAdminName ||
                          'Анкета для руководителей и специалистов'
                        }
                        type="Скачать  .doc"
                        link={formAdmin}
                      />
                    )}
                  </ContactFormLinkList>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="text"
                  name="firstName"
                  required
                  placeholder="Имя"
                  autoComplete="off"
                  setFormValue={(value: string) => setFirstName(value)}
                />
              </Col>
              <Col>
                <Input
                  type="text"
                  name="lastName"
                  required
                  placeholder="Фамилия"
                  autoComplete="off"
                  setFormValue={(value: string) => setLastName(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                  autoComplete="off"
                  setFormValue={(value: string) => setEmail(value)}
                />
              </Col>
              <Col>
                <MaskedTextInputFormik
                  name="phone"
                  placeholder="Телефон"
                  mask="{+375}(00)000-00-00"
                  clearErrorOnChange
                  required
                  data-testid="phone-field"
                  autoComplete="off"
                  isSentSuccess={isSentSuccess}
                  setFormValue={(value: string) => setPhone(value)}
                />
              </Col>
            </Row>
          </Center>
          <AttachWrapper>
            <UploadFileFinal
              name={'fileId'}
              type={'file'}
              isSmall={true}
              fileId={fileId}
              setFileId={setFileId}
              isSentSuccess={isSentSuccess}
              setFile={(value: boolean) => setFile(value)}
              description={`Перетащите сюда файл с резюме или <span>загрузите</span> его с
                      компьютера (до 5 Мб)`}
            />
          </AttachWrapper>
          {!!formCheckboxes.length && (
            <CheckboxWrapper>
              {formCheckboxes.map((checkbox, index) => (
                <Checkbox
                  key={index}
                  checked={checkboxValues[index]}
                  onChange={() =>
                    setCheckboxValues(
                      checkboxValues.map((checkbox, checkboxIndex) =>
                        checkboxIndex === index ? !checkbox : checkbox
                      )
                    )
                  }
                  label={checkbox.text ?? ''}
                  id={'vacancy-form-checkbox-' + index}
                />
              ))}
            </CheckboxWrapper>
          )}
          <Bottom>
            <SendButton
              variant="orange"
              type="submit"
              isSubmitting={isSubmitting}
              isDisabled={btnDisabled}
            >
              {!isSubmitting ? (
                'Отправить резюме'
              ) : (
                <Spinner color="orange" absolute={true} />
              )}
            </SendButton>
          </Bottom>
        </FormInner>
      </FormContent>
    </Form>
  );
}

const FormRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FormContent = styled.fieldset`
  border: none;
  position: relative;
  padding: 0;
  width: 100%;
  flex: 1 1 auto;
`;

const FormInner = styled.div<{ isSentSuccess: boolean }>`
  transition: all 0.3s ease-in-out;
  ${(props) =>
    props.isSentSuccess
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `};
`;

const Top = styled.div`
  max-width: 648px;
  margin-bottom: 45px;

  ${media.laptop(css`
    max-width: 500px;
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    max-width: 450px;
    margin-bottom: 30px;
  `)}
  
   ${media.mobile(css`
    margin-bottom: 50px;
    max-width: 240px;
  `)}
`;

const Center = styled.div`
  margin: -11px -16px;

  ${media.laptop(css`
    margin: -14px -12px;
  `)}
`;

const AttachWrapper = styled.div`
  margin-top: 40px;

  ${media.laptop(css`
    margin-top: 33px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
  `)}
  
  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 50px;
  text-align: center;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Col = styled.div`
  flex: 1 1 50%;
  position: relative;
  padding: 11px 16px;

  ${media.laptop(css`
    padding: 14px 12px;
  `)}

  input {
    border: none;
    border-bottom: 2px solid ${colors.black};
    padding: 8px 0;
    height: 40px;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: ${colors.black};

    ${media.laptop(css`
      padding: 4px 0;
      height: 30px;
      border-bottom: 1px solid ${colors.black};
      font-size: 20px;
      line-height: 26px;
    `)}

    ${media.tablet(css`
      font-size: 18px;
      line-height: 22px;
    `)}
    
     ${media.mobile(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    &::placeholder {
      color: ${colors.grayLight};

      ${media.laptop(css`
        font-size: 20px;
        line-height: 26px;
      `)}

      ${media.tablet(css`
        font-size: 18px;
        line-height: 22px;
      `)}
      
      ${media.mobile(css`
        font-size: 14px;
        line-height: 20px;
      `)}
    }
  }

  span {
    right: 16px;

    ${media.laptop(css`
      right: 12px;
    `)}
  }
`;

const Input = styled(TextInputFormik)`
  input {
    border: none;
    border-bottom: 2px solid ${colors.black};
    padding: 8px 0;
    height: 40px;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: ${colors.black};

    ${media.laptop(css`
      padding: 4px 0;
      height: 30px;
      border-bottom: 1px solid ${colors.black};
      font-size: 20px;
      line-height: 26px;
    `)}

    ${media.tablet(css`
      font-size: 18px;
      line-height: 22px;
    `)}
    
    ${media.mobile(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    &::placeholder {
      color: ${colors.grayLight};

      ${media.laptop(css`
        font-size: 20px;
        line-height: 26px;
      `)}

      ${media.tablet(css`
        font-size: 18px;
        line-height: 22px;
      `)}
      
      ${media.mobile(css`
        font-size: 14px;
        line-height: 20px;
      `)}
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const SendButton = styled(Button)<{
  isSubmitting: boolean;
  isDisabled?: boolean;
}>`
  position: relative;
  display: block;
  min-height: 70px;
  height: 100%;
  width: 100%;
  padding: 19px 35px;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  transition: all 0.3s linear;

  ${media.laptop(css`
    min-height: 50px;
    padding: 9px 35px;
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}

  ${(props) =>
    props.isSubmitting
      ? css`
          border: none;
          pointer-events: none;
        `
      : css``}
  
    ${(props) =>
    props.isDisabled
      ? css`
          pointer-events: none;
          background: ${colors.gray50};
          color: ${colors.black};
        `
      : ''}
`;

const CheckboxWrapper = styled.div`
  margin-top: 10px;
`;

const ContactFormLinkList = styled.div``;

export default ContactsForm;
