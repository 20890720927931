import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  title: string;
  type: string;
  link: string;
};

function ContactFormLinkCard({ title, type, link }: Props) {
  return (
    <Component>
      <QuestionnaireCardLink target="_blank" download href={link}>
        <Inner>
          <Top>
            <Title>{title}</Title>
          </Top>
          <Bottom>
            <FileType>{type}</FileType>
          </Bottom>
        </Inner>
      </QuestionnaireCardLink>
    </Component>
  );
}

const QuestionnaireCardLink = styled.a``;

const Inner = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background: ${colors.black500};
  transition: all 0.3s ease-in-out;
`;

const Top = styled.div``;

const Bottom = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-size: 20px;
  line-height: 30px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const FileType = styled.span`
  font-size: 20px;
  line-height: 26px;
  color: ${colors.white};
  margin-left: 10px;
  white-space: nowrap;

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const Component = styled.div`
  width: 100%;
  overflow: hidden;

  &:hover {
    ${Inner} {
      background: ${colors.orange};
    }
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export default ContactFormLinkCard;
